<template>
<div>
    <h1>Order payment status:</h1>
    {{ order }}
</div>
</template>

<script>
import {
    reactive
} from "vue";
import {
    useRouter
} from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const order = reactive({
            status: router.currentRoute.value.query.status,
            REFNO: router.currentRoute.value.query.REFNO,
        });
        return {
            order,
        };
    },
};
</script>

<style></style>
